import React from 'react';
import { Link } from 'react-router-dom';
import MapComponent from './Map';

function Footer() {
    return (
        <div>
            <footer id="locationSec" className="footer section gray-bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="widget mb-5 mb-lg-0">
                                <h4 className="text-capitalize mb-3"> About</h4>
                                <div className="divider mb-4"></div>
                                <p>Our services are unparalleled and we strive to give you the best electrical service without
                                    sacrificing quality. We set high standards and we strive to meet those standards every day. </p>
                                <p>Regardless of the scope and size of your project, you can rely on us to get the job done safely, efficiently, on time, and within budget. If you want to schedule an appointment, or want to learn more, about our company, get in touch with us today.</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="widget mb-5 mb-lg-0">
                                <h4 className="text-capitalize mb-3">Quick Link</h4>
                                <div className="divider mb-4"></div>
                                <ul className="list-unstyled footer-menu lh-35">
                                    <li className="nav-item active"><Link className="nav-link" to="/">Home</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/services">Services</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/gallery">Gallery</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="widget widget-contact mb-5 mb-lg-0">
                                <h4 className="text-capitalize mb-3">Get in Touch</h4>
                                <div className="divider mb-4"></div>

                                <div className="footer-contact-block mb-4">
                                    <div className="icon d-flex align-items-center">
                                        <i className="icofont-home mr-3"></i>
                                        <span className="h6 mb-0">4210,SATURN WAY, UNION CITY, CA, 94587</span>
                                    </div>
                                </div>
                                <div className="footer-contact-block mb-4">
                                    <div className="icon d-flex align-items-center">
                                        <i className="icofont-phone mr-3"></i>
                                        <span className="h6 mb-0">(510) 779-4422</span>
                                    </div>
                                </div>
                                <div className="footer-contact-block mb-4">
                                    <div className="icon d-flex align-items-center">
                                        <i className="icofont-email mr-3"></i>
                                        <span className="h6 mb-0"> caelectric911@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="widget widget-contact">
                                <h4 className="text-capitalize mb-3">Location</h4>
                                <div className="divider mb-4"></div>
                                <MapComponent />
                            </div>
                        </div>
                    </div>

                    <div className="footer-btm py-4">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright text-center">
                                    &copy; All Rights Reserved <b>CA Electrical Solutions</b>
                                </div>
                            </div>
                        </div>
                        {/* <a className="backtop js-scroll-trigger" href="#top"><i className="icofont-long-arrow-up"></i></a> */}
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;